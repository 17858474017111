
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {

  //STAGING
  // baseUrl2 = 'https://api.cashbackpanel.bigcityvoucher.co.in/v1';
  // baseUrl1 = 'https://api.bcpvoucher.bigcityvoucher.co.in/v1';
  imageUrl = 'https://api.hubadmin.bigcitytrade.bigcityvoucher.co.in';



  //prod
  baseUrl2 = 'https://api.cashback.bigcitydays.in/v1';
  baseUrl1 = 'https://api.cashbackvoucher.bigcitydays.in/v1';

  //Oreo Prod
  // baseUrl2 = 'https://api.cashback.oreo.bigcitydays.in/v1';
  // baseUrl1 = 'https://api.cashbackvoucher.oreo.bigcitydays.in/v1';





  login = this.baseUrl2 + "/user/login";
  logout = this.baseUrl2 + "/user/logout"

  //Dashboard
  getDashboardCount = this.baseUrl2 + "/cashback-report/dashboard?";
  getModule = this.baseUrl2 + "/user/list-modules?";


  //user
  createUser = this.baseUrl2 + "/user/create-user";
  getUserList = this.baseUrl2 + "/user/get-users";
  getRoles = this.baseUrl2 + "/user/get-roles";
  getAllModules = this.baseUrl2 + "/user/all-modules";
  getUserDetails = this.baseUrl2 + "/user/get-user-details?";

  //campaign
  createCampaign = this.baseUrl2 + "/campaign/create-campaign";
  getCampaignList = this.baseUrl2 + "/campaign/campaign-list";
  updateCampaign = this.baseUrl2 + "/campaign/update-campaign";
  getCampaignDetails = this.baseUrl2 + "/campaign/campaign";

  getCashFree = this.baseUrl2 + "/cashback-process/payment-wallets?vendor_id=1";
  getPaytmWallet = this.baseUrl2 + "/cashback-process/payment-wallets?vendor_id=2";
  generateSecret_Key = this.baseUrl2 + "/cashback-process/generate-secret-key";
  getAccessModule = this.baseUrl2 + "/cashback-process/payment-wallets?vendor_id=1";
  getUserDropDpown = this.baseUrl2 + "/campaign/user-access-list";


  addCampaigns = this.baseUrl2 + "/campaign/create-campaign";
  getcampaignReport = this.baseUrl2 + "/cashback-report/cashback_report?"
  requestVoucher = this.baseUrl2 + "/campaign/additional-voucher"
  bulkGenerate = this.baseUrl1 + "/voucher/bulk-genrate"
  downloadVoucher = this.baseUrl1 + "/voucher/export-voucher"
  getDenominationDrop = this.baseUrl2 + "/campaign/denomination-list";
  getDenominationList = this.baseUrl1 + "/campaign/denomination-list";
  enableDisableCamp = this.baseUrl2 + "/cashback-process/close-campaign";

  //cashbackList
  getCashbackList = this.baseUrl2 + "/cashback-process/cashbacklisting?";
  approveRejectCashback = this.baseUrl2 + "/cashback-process/arcashback?";
  getPaymentMethod = this.baseUrl2 + "/cashback-process/getpaymentmethods";
  getCashGramDropdown = this.baseUrl2 + "/cashback-report/cashgram-status-list?";
  getInternalStatusDropdown = this.baseUrl2 + "/cashback-report/internal-status-list?";
  getCustomCampaignList = this.baseUrl2 + "/cashback-report/custom-campaign-list";
  getCustomCampaignDropDown = this.baseUrl2 + "/cashback-report/campaign-list-report";
  getAllPendingCapaign = this.baseUrl2 + "/cashback-process/cashback-pending-requests?";
  getReportDropDown = this.baseUrl2 + "/cashback-report/list-of-reports";
  getAllEmailList = this.baseUrl2 + "/cashback-report/list-emails";
  filterReport = this.baseUrl2 + "/cashback-report/report-listing?"


  //Agent Report
  getAgentReportList = this.baseUrl2 + "/cashback-report/agent-report?";

  //Payment Configuration Wallets
  getPaymentConfigList = this.baseUrl2 + "/cashback-configration/list-payment-wallets";
  getPaymentVender = this.baseUrl2 + "/cashback-configration/payment-vendors";
  addUpdateWallet = this.baseUrl2 + "/cashback-configration/add-update-wallets";

}
export interface ApiConfigurationInterface { }